<script lang="ts" setup>
import { useRouter, useRoute } from 'nuxt/app'
import { useI18n } from 'vue-i18n'
import { useScreen } from '@/composables/useScreen'
import IconPlus from '@/assets/icons/plus.svg'
import IconMinus from '@/assets/icons/minus.svg'

const { isMobileView } = useScreen()
const router = useRouter()
const route = useRoute()
const { signOut, session, user } = useSession()
const confirmLogoutVisible = ref(false)
const { locale, availableLocales, messages } = useI18n()
const profilePopoverRef = ref()
const isMobileMenuOpened = ref(false)
const isMobileProfileExpanded = ref(false)
const localePath = useLocalePath()
const isHomePage = computed(() => route.fullPath === localePath('/'))

const extractMessage = (messageObject: any) => messageObject?.body?.static || messageObject?.b?.s

const handleLocaleChange = (payload: string) => {
  // translate route params
  const params = Object.entries(route.params).reduce((acc, [key, value]) => {
    // Skip the translation for specific routes
    if (['firmLawyerId', 'id', 'slug'].includes(key)) return { ...acc, [key]: value };

    // find locale key
    const translationKey = Object.entries(messages.value[locale.value]).map(([mKey, mValue]) => {
      // non binary value stored in `message.body.static`
      const value = extractMessage(mValue)
      if (value && typeof route.params[key] === 'string' && value.toLowerCase() === route.params[key]?.toLowerCase()) {
        return mKey
      }
      
    }).filter(Boolean)[0] // filter array from undefined items and retrieve first

    if (!translationKey) return acc

    // assign translation if exists
    const message = extractMessage(messages.value[payload][translationKey])

    return message ? {
      ...acc,
      [key]: message ? message.toLowerCase() : null
    } : { ...acc }
  }, {})

  if (Object.values(params).length !== Object.values(route.params).length) {
    console.error('Route params mismatch. Translation failed')
    return
  }

  // update new route
  router.push({
    name: `${route?.name?.toString().split('___')[0]}___${payload}`,
    query: route.query,
    params
  })
}

// Forcing browser to rerender image when it is updated.
const getUpdatedPhotoUrl = (url: string) => {
  const timestamp = new Date().getTime()
  return `${url}?t=${timestamp}`
}

const isSessionAvailable = computed(() => {
  return Boolean(user.value)
})

const dialogConfirm = () => {
  confirmLogoutVisible.value = false
  isMobileMenuOpened.value = false
  profilePopoverRef.value.hide()

  signOut()
}
</script>
<template>
  <div class="container font-branding font-semibold z-[100]">
    <teleport to="body">
      <GTModal
        :isOpen="confirmLogoutVisible"
        :width="440"
        :closeIcon="true"
        @close="confirmLogoutVisible = false"
        @confirm="dialogConfirm"
      >
        <template #header>
          <div class="mb-3 -mt-2">
            {{ $t('confirmation') }}
          </div>
        </template>

        <template #content>
          <div class="my-6">
            {{ $t('do_you_really_want_to') }} <strong>{{ $t('logout') }}</strong>?
          </div>
        </template>
      </GTModal>
  </teleport>
    <div v-if="isMobileView">
      <el-drawer v-model="isMobileMenuOpened" :show-close="false" size="100%" style="background-color: #0B112C; min-height: 400px;">
        <template #header="{ close }">
          <NuxtLinkLocale to="/" class="ml-1" @click="close">
            <IconGtLogo class="text-white max-w-[150px]" />
          </NuxtLinkLocale>
          <button class="mr-1 bg-white hover:bg-gray-100 active:bg-gray-200 transition rounded-full p-2" @click="close">
            <IconCloseButton class="size-6" />
          </button>
        </template>
        <template #default>
          <div class="text-white font-redhat text-3xl grid gap-4">
            <div>
              <NuxtLinkLocale to="/" class="ml-1" @click="isMobileMenuOpened = false">
                {{ $t('home') }}
              </NuxtLinkLocale>
            </div>
            <div>
              <NuxtLinkLocale
                :to="{
                  name: 'lawyers-specialty',
                  params: { specialty: $t('advocates').toLowerCase() }
                }"
                class="ml-1"
                @click="isMobileMenuOpened = false"
              >
                {{ $t('lawyers') }}
              </NuxtLinkLocale>
            </div>
            <div v-if="user?.hasRole('ROLE_USER')">
              <NuxtLinkLocale to="services" class="ml-1" @click="isMobileMenuOpened = false">
                {{ $t('services') }}
              </NuxtLinkLocale>
            </div>
            <div v-if="user">
              <button class="flex items-center" @click="isMobileProfileExpanded = !isMobileProfileExpanded">
                <el-avatar :src="getUpdatedPhotoUrl(user.photo)">
                  <IconBlankUser alt="reviewer" class="scale-75" />
                </el-avatar>
                <div class="ml-3">
                  {{ $t('profile') }}
                </div>
                <div class="ml-20">
                  <component :is="!isMobileProfileExpanded ? IconPlus : IconMinus" class="size-4" />
                </div>
              </button>
              <transition>
                <div v-if="isMobileProfileExpanded" class="mt-6 text-lg">
                  <div>
                    <NuxtLinkLocale to="account-index" @click="isMobileMenuOpened = !isMobileMenuOpened">
                      {{ $t('settings') }}
                    </NuxtLinkLocale>
                  </div>
                  <div v-if="user?.hasRole('ROLE_LAWYER')" class="mt-4">
                    <NuxtLinkLocale to="account-index-profile" @click="isMobileMenuOpened = !isMobileMenuOpened">
                      {{ $t('lawyer_profile') }}
                    </NuxtLinkLocale>
                  </div>
                  <div v-if="user?.hasRole('ROLE_FIRM')" class="mt-4">
                    <NuxtLinkLocale to="account-index-firm" @click="isMobileMenuOpened = !isMobileMenuOpened">
                      {{ $t('Firm_profile') }}
                    </NuxtLinkLocale>
                  </div>
                  <div class="horizontal-separator mt-6 mb-6" />
                  <button class="flex items-center" @click="confirmLogoutVisible = true">
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 18.0552C1.45 18.0552 0.979167 17.8593 0.5875 17.4677C0.195833 17.076 0 16.6052 0 16.0552V2.05518C0 1.50518 0.195833 1.03434 0.5875 0.642676C0.979167 0.251009 1.45 0.0551758 2 0.0551758H9V2.05518H2V16.0552H9V18.0552H2ZM13 14.0552L11.625 12.6052L14.175 10.0552H6V8.05518H14.175L11.625 5.50518L13 4.05518L18 9.05518L13 14.0552Z" fill="#FFFFFF" />
                    </svg>
                    <div class="ml-2">
                      {{ $t('logout') }}
                    </div>
                  </button>
                </div>
              </transition>
            </div>
            <div v-else class="w-full">
              <div class="flex justify-center ">
                <NuxtLinkLocale
                  to="login"
                  class="mobile-login-button bg-white text-darkBlue text-lg flex justify-center items-center"
                >
                  {{ $t('login') }}
                </NuxtLinkLocale>
              </div>
            </div>
            <div v-if="availableLocales && locale" class="mobile-language-select">
              <el-select v-model="locale">
                <el-option v-for="conreteLocale in availableLocales" :key="conreteLocale" :label="conreteLocale" :value="conreteLocale" />
              </el-select>
            </div>
          </div>
        </template>
      </el-drawer>
      <div class="navigation-bar-mobile flex items-center w-full">
        <NuxtLinkLocale to="/" class="ml-6">
          <IconGtLogo class="w-[150px] transition duration-[400]" :class="[isHomePage ? 'text-white' : 'text-darkBlue']" />
        </NuxtLinkLocale>
        <div class="ml-auto mr-6">
          <button class="transition duration-[400]" :class="[isHomePage ? 'text-white' : 'text-darkBlue']" @click="isMobileMenuOpened = true">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 18V16H21V18H3ZM3 13V11H21V13H3ZM3 8V6H21V8H3Z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="navigation-bar sticky top-0 flex items-center leading-none">
        <div class="flex items-center w-full h-full">
          <NuxtLinkLocale to="/" class="flex items-center h-full">
            <IconGtLogo class="text-darkBlue w-full max-w-[212px]"/>
          </NuxtLinkLocale>
          <div class="flex items-center h-full ml-auto text-md">
            <div class="md:flex">
              <NuxtLinkLocale
                v-if="user?.hasRole('ROLE_USER')"
                to="services"
                class="mr-6 text-darkBlue"
              >
                {{ $t('services') }}
              </NuxtLinkLocale>
              <div>
                <NuxtLinkLocale
                  :to="{
                    name: 'lawyers-specialty',
                    params: { specialty: $t('advocates').toLowerCase() }
                  }"
                  class="mr-6 text-darkBlue"
                >
                  {{ $t('advocates') }}
                </NuxtLinkLocale>
              </div>
              <div v-if="availableLocales && locale" class="text-darkBlue mr-6">
                <GTDropdown :title="locale" labelClass="first-letter:capitalize">
                  <template #default="{ closeDropdown }">
                    <GTDropdownOptions
                      class="min-w-[50px]"
                      :data="availableLocales.map(x => ({ id: x, label: x }))"
                      labelClass="first-letter:capitalize"
                      @change="({ id }) => {
                        closeDropdown()
                        handleLocaleChange(id)
                      }"
                    />
                  </template>

                </GTDropdown>
              </div>
            </div>
            <template v-if="isSessionAvailable">
              <div class="ml-2 mr-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 19V17H6V10C6 8.61667 6.41667 7.3875 7.25 6.3125C8.08333 5.2375 9.16667 4.53333 10.5 4.2V3.5C10.5 3.08333 10.6458 2.72917 10.9375 2.4375C11.2292 2.14583 11.5833 2 12 2C12.4167 2 12.7708 2.14583 13.0625 2.4375C13.3542 2.72917 13.5 3.08333 13.5 3.5V4.2C14.8333 4.53333 15.9167 5.2375 16.75 6.3125C17.5833 7.3875 18 8.61667 18 10V17H20V19H4ZM12 22C11.45 22 10.9792 21.8042 10.5875 21.4125C10.1958 21.0208 10 20.55 10 20H14C14 20.55 13.8042 21.0208 13.4125 21.4125C13.0208 21.8042 12.55 22 12 22Z" fill="#0E1535" />
                </svg>
              </div>
              <el-popover
                ref="profilePopoverRef"
                placement="bottom-end"
                :show-arrow="false"
                :width="240"
                trigger="click"
                popper-style="border-radius: 20px; top: 75px;"
                popper-class="hidden lg:block"
              >
                <template #reference>
                  <button>
                    <div v-if="session?.user" class="profile-button pt-2 pr-0 pb-2 pl-1">
                      <el-avatar :src="getUpdatedPhotoUrl(session?.user.photo)">
                        <IconBlankUser alt="reviewer" class="scale-75" />
                      </el-avatar>
                    </div>
                  </button>
                </template>
                <template #default>
                  <div class="popup-triangle" />
                  <div id="profile-popup-content-container">
                    <div class="flex justify-center mt-3">
                      <el-avatar :src="getUpdatedPhotoUrl(session?.user.photo)" :size="80">
                        <IconBlankUser alt="reviewer" class="scale-75" />
                      </el-avatar>
                    </div>
                    <div class="flex justify-center mt-3 text-lg text-darkBlue">
                      {{ session?.user.name }} {{ session?.user.lastname }}
                    </div>
                    <div class="horizontal-separator mt-4 mb-4" />
                    <div>
                      <NuxtLinkLocale to="account-index" class="ml-1 p-2 text-md text-darkBlue" @click="profilePopoverRef.hide()">
                        {{ $t('settings') }}
                      </NuxtLinkLocale>
                    </div>
                    <div v-if="user?.hasRole('ROLE_LAWYER')" class="mt-4">
                      <NuxtLinkLocale to="account-index-profile" class="ml-1 p-2 text-md text-darkBlue" @click="profilePopoverRef.hide()">
                        {{ $t('lawyer_profile') }}
                      </NuxtLinkLocale>
                    </div>
                    <div v-if="user?.hasRole('ROLE_FIRM')" class="mt-4">
                      <NuxtLinkLocale to="account-index-firm" class="ml-1 p-2 text-md text-darkBlue" @click="profilePopoverRef.hide()">
                        {{ $t('firm_profile') }}
                      </NuxtLinkLocale>
                    </div>
                    <div class="horizontal-separator mt-4" />
                    <button class="ml-3 mb-3 flex" @click="confirmLogoutVisible = true">
                      <svg
                        class="mt-4.5"
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M2 18.0552C1.45 18.0552 0.979167 17.8593 0.5875 17.4677C0.195833 17.076 0 16.6052 0 16.0552V2.05518C0 1.50518 0.195833 1.03434 0.5875 0.642676C0.979167 0.251009 1.45 0.0551758 2 0.0551758H9V2.05518H2V16.0552H9V18.0552H2ZM13 14.0552L11.625 12.6052L14.175 10.0552H6V8.05518H14.175L11.625 5.50518L13 4.05518L18 9.05518L13 14.0552Z" fill="#0E1535" />
                      </svg>
                      <span class="text-md text-darkBlue mt-2 pl-2 pt-2 pr-2">{{ $t('logout') }}</span>
                    </button>
                  </div>
                </template>
              </el-popover>
            </template>
            <template v-else>
              <div class="flex items-center text-white">
                <NuxtLinkLocale
                  to="login"
                  class="login-button ml-2 py-2 px-4 max-w-[105px] h-[40px] rounded-[9999px] inline-flex items-center justify-center w-full bg-darkBlue font-normal"
                >
                  {{ $t('login') }}
                </NuxtLinkLocale>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.navigation-bar {
  height: 88px;
}

.navigation-bar-mobile {
  height: 72px;
  background: transparent;
}

.absolute-position {
  position: absolute;
}

.mobile-login-button {
  position: absolute;
  bottom: 112px;
  height: 48px;
  width: 327px;
  border-radius: 9999px;
}

.mobile-language-select {
  position: absolute;
  bottom: 20px;
  right: 24px;
  width: 60px;
}

.horizontal-separator {
  border-bottom: 1px solid #e8eaee;
}

/* Outer triangle (gray border) */
.popup-triangle::before {
  content: '';
  position: absolute;
  top: -22px;
  right: 16px;
  border-style: solid;
  border-width: 11px 5px 11px 14px;
  border-color: transparent transparent rgb(228, 231, 237) transparent;
}

/* Inner triangle (white) */
.popup-triangle::after {
  content: '';
  position: absolute;
  top: -19px;
  right: 17px;
  border-style: solid;
  border-width: 10px 4px 10px 14px;
  border-color: transparent transparent #FFFFFF transparent;
}

/* transition settings*/
.v-enter-active,
.v-leave-active {
  transition: opacity 0.75s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
